import React, { useEffect, useState } from 'react';
import './MainCustomerView.css';
import {
    Affix, Alert, Button, Drawer, message, Modal, Spin, Tooltip,
} from 'antd';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Progress from '../../Common/Progress/Progress';
import SlotSelection from '../../Common/SlotSelection/SlotSelection';
import CustomerForm from '../../Common/CustomerForm/CustomerForm';
import Summary from '../../Common/Summary/Summary';
import Footer from '../../Common/Footer/Footer';
import BookingConfirmation from '../../Common/Confirmation/BookingConfirmation';
import AdminHeader from '../../Common/adminHeader/adminHeader';
import TechTracking from '../../Common/techTracking/TechTracking';
import WorkOrderSummaryPage from '../WorkOrderSummaryPage';

enum WorkOrderState {
    NO_SCHEDULING = 'NO_SCHEDULING',
    IN_PROGRESS = 'IN_PROGRESS',
    ETA = 'ETA',
    UNDEFINED = 'UNDEFINED',
}

interface WorkOrderAlert {
    workOrderId: number,
    alertType: string,
    alertValue: string,
}

enum AchievementAlertValue {
    RISK = 'RISK',
    LATE = 'LATE',
    FAILED = 'FAILED',
}

interface HelpMessageConfiguration {
    showHelp: boolean,
    helpMessage: string,
    helpSupportPhone: string,
    helpSupportEmail: string,
}

interface UnauthorizedSchedulePageConfiguration {
    title: string,
    description: string,
    phoneNumber: string,
}

interface UrlConfiguration {
    url: string,
}

interface GetSlotAttributesConfiguration {
    startDateDelay: number,
    endDateDelay: number
}

export default function MainCustomerView(this: any) {
    const DETAILS_FORM = 0;
    const SLOT_SELECTION = 1;
    const SUMMARY_FORM = 2;
    const CONFIRMATION_PAGE = 3;
    const FINISH_PAGE = 4;

    const [t, i18n] = useTranslation('common');
    const [selectedSlot, setSelectedSlot] = useState<any>();
    const [travelTime, setTravelTime] = useState<number>();
    const [workOrderState, setWorkOrderState] = useState<WorkOrderState>();
    const [alert, setAlert] = useState<WorkOrderAlert>();
    const [step = DETAILS_FORM, setStep] = useState<number>();
    const [buttonState = t('main.buttons.next'), setButtonState] = useState<string>();
    const [openModal, setOpenModal] = useState<boolean>();
    const [additionalInfo = '', setAdditionalInfo] = useState<string>();
    const [workOrder, setWorkOrder] = useState<any>();
    const [eta, setEta] = useState<any>();
    const [newPhoneNumber, setNewPhoneNumber] = useState<any>();
    const [newEmailAddress, setNewEmailAddress] = useState<any>();
    const [formValidation, setFormValidation] = useState<boolean>();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [helpMessageConfiguration, setHelpMessageConfiguration] = useState<HelpMessageConfiguration>();
    const [unauthorizedSchedulePageConfiguration, setUnauthorizedSchedulePageConfiguration] = useState<UnauthorizedSchedulePageConfiguration>();
    const [getSlotAttributesConfiguration, setGetSlotAttributesConfiguration] = useState<GetSlotAttributesConfiguration>();
    const { uuid } = useParams();
    // The user clicked on the "Reschedule the appointment".
    const [isRescheduling, setIsRescheduling] = useState(false);

    function redirectToCustomUrl(urlConfiguration: UrlConfiguration) {
        if (urlConfiguration.url && urlConfiguration.url !== `${window.location.protocol}//${window.location.host}`) {
            window.location.replace(`${urlConfiguration.url}${window.location.pathname}`);
        }
    }

    function applyDisplayConfiguration(displayConfiguration) {
        const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
        if (link) {
            link.href = displayConfiguration.favicon;
        }
        document.documentElement.style.setProperty('--desktop-logo-url', `url(${displayConfiguration.masterLogo})`);
        document.documentElement.style.setProperty('--mobile-logo-url', `url(${displayConfiguration.smallLogo})`);
        setHelpMessageConfiguration(displayConfiguration.helpSection);
        setUnauthorizedSchedulePageConfiguration(displayConfiguration.unauthorizedSchedulePageConfiguration);
        i18n.changeLanguage(displayConfiguration.language);
    }

    async function getTenantConfiguration(publicId) {
        const result = await fetch(`/service/config/${publicId}`)
            .then((response) => response.json())
            .catch((error) => console.error(error));
        if (result !== undefined) {
            setGetSlotAttributesConfiguration(result.getSlotAttributesConfiguration);
            applyDisplayConfiguration(result.displayConfiguration);
            redirectToCustomUrl(result.urlConfiguration);
        }
    }

    function setStepFromStatus(stepWorkOrder) {
        // WHEN the status is PRE-SCHEDULED, SCHEDULED or IN PROGRESS, redirect to the "Appointment Confirmed" page.
        if (stepWorkOrder.status === 2 || stepWorkOrder.status === 3 || stepWorkOrder.status === 4) {
            setStep(3);
            setButtonState(t('main.buttons.confirm'));
            // When the status is COMPLETED or VALIDATED, redirect to the "Finish" page.
        } else if (stepWorkOrder.status >= 5) {
            setStep(4);
        }
    }

    function getAlert(publicId: string) {
        return fetch(`/service/${publicId}/alert`)
            .then((response) => (response.status === 200 ? response.json() : null))
            .then((alert) => {
                if (alert) {
                    setAlert(alert);
                }
            })
            .catch((error) => console.error(error));
    }

    function getETA(publicId: string) {
        return fetch(`/service/${publicId}/eta`)
            .then((response) => response.json())
            .then((eta) => {
                if (eta) {
                    setEta(eta);
                    setTravelTime(eta.travelTime);
                }
            })
            .catch((error) => console.error(error));
    }

    async function getWorkOrderState(publicId) {
        return fetch(`/service/${publicId}/state`)
            .then((response) => response.json())
            .then((workOrderState) => {
                if (workOrderState) {
                    setWorkOrderState(workOrderState);
                }
            })
            .catch((error) => console.error(error));
    }

    async function getWorkOrder(publicId) {
        const result = await fetch(`/service/${publicId}`)
            .then((response) => response.json())
            .catch((error) => console.error(error));

        if (result !== undefined) {
            await Promise.all([getAlert(publicId), getETA(publicId)]);
            setWorkOrder(result);
            setStepFromStatus(result);
            setNewEmailAddress(result.customer?.email);
            setNewPhoneNumber(result.customer?.phone);
            setAdditionalInfo(result.siteDescription);
            getTenantConfiguration(publicId);
        }
    }

    const onClose = () => {
        setOpenDrawer(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            await getWorkOrderState(uuid);
            await getWorkOrder(uuid);
        };
        fetchData().then();
    }, [uuid]);

    async function handleNextStep() {
        if (step === DETAILS_FORM) {
            if (formValidation) {
                setStep(step + 1);
                setSelectedSlot(undefined);
            }
        }
        if (step === SLOT_SELECTION) {
            setButtonState(t('main.buttons.confirm'));
            setStep(step + 1);
        }
        if (step === SUMMARY_FORM) {
            const response = await handleConfirm();
            if (!response.ok) {
                message.error(workOrder.status === 1 ? t('booking.error.scheduled') : t('booking.error.rescheduled'), 5);
            } else {
                /* notification.info({
                    message: t('booking.info.title'),
                    className: 'notification notification-info',
                    icon: <FontAwesomeIcon icon="circle-info" className="iconBig icon-primary" />,
                    closeIcon: <FontAwesomeIcon icon="xmark" className="iconBig icon-grey" />,
                    description: t('booking.info.description'),
                    placement: 'top',
                    duration: 15,
                }); */
                getWorkOrder(uuid); // Pourquoi ?
                setButtonState(t('main.buttons.confirm'));
                setStep(step + 1);
            }
        }
    }

    function updateWorkOrder(nworkOrder) {
        setWorkOrder(nworkOrder);
    }

    function resetSlot() {
        setSelectedSlot(undefined);
    }

    function selectSlot(slot) {
        setSelectedSlot(slot);
    }

    function newAdditionalInfo(info) {
        const stepWorkOrder = workOrder;
        stepWorkOrder.siteDescription = info.target.value;
        setWorkOrder(stepWorkOrder);
        setAdditionalInfo(info.target.value);
    }

    function handleOpenModal() {
        setOpenModal(true);
    }

    function handleCloseModal() {
        setOpenModal(false);
    }

    function validateForm(validation) {
        setFormValidation(validation);
    }

    function changePhoneNumber(number) {
        setNewPhoneNumber(number);
    }

    function changeEmailAddress(address) {
        setNewEmailAddress(address);
    }

    let customerForm;
    let modal;
    let affix;
    if (workOrder !== undefined) {
        customerForm = workOrder.error === undefined ? [<CustomerForm
            key="customerForm"
            workOrder={workOrder}
            updateWorkOrder={updateWorkOrder}
            validateForm={validateForm}
            setPhoneNumber={changePhoneNumber}
            setEmailAddress={changeEmailAddress}
            additionalInfo={additionalInfo}
            newAdditionalInfo={newAdditionalInfo}
        />] : [];
        modal = workOrder.error === undefined ? [
            <Modal
                key="modal"
                className="contact-modal"
                title={t('affix.title')}
                open={openModal}
                onCancel={() => handleCloseModal()}
                footer={[
                    <Button
                        key="okContactModal"
                        className="contact-modal-button"
                        onClick={() => handleCloseModal()}
                    >
                        OK
                    </Button>,
                ]}
            >
                <p className="modal-desc">{t(helpMessageConfiguration?.helpMessage ?? '')}</p>
                {
                    helpMessageConfiguration?.helpSupportEmail || helpMessageConfiguration?.helpSupportPhone
                        ? <p className="contact-title">{t('affix.contact')}</p> : null
                }

                {helpMessageConfiguration?.helpSupportPhone ? (
                    <p>
                        <a href={`tel:${helpMessageConfiguration?.helpSupportPhone}`}>{helpMessageConfiguration?.helpSupportPhone.replace(/(.{2})/g, '$& ')}</a>
                    </p>
                ) : null}

                {helpMessageConfiguration?.helpSupportEmail ? (
                    <p>
                        <a
                            className="email-link"
                            href={`mailto:${helpMessageConfiguration?.helpSupportEmail}`}
                        >
                            {helpMessageConfiguration?.helpSupportEmail}
                        </a>
                    </p>
                ) : null}

            </Modal>,
        ] : [];

        affix = helpMessageConfiguration?.showHelp ? [
            <Affix key="affix" className="help-button">
                <div>
                    <Button shape="circle" className="help-affix" onClick={() => handleOpenModal()}>?</Button>
                    {modal}
                </div>
            </Affix>,
        ] : undefined;
    }

    function rescheduleAppointment() {
        setStep(1);
        setIsRescheduling(true);
        setSelectedSlot(undefined);
        setButtonState(t('main.buttons.next'));
    }

    function handlePreviousStep() {
        if (step === SUMMARY_FORM) {
            setButtonState(t('main.buttons.next'));
            setSelectedSlot(undefined);
            setStep(step - 1);
        } else if (step > DETAILS_FORM) {
            setButtonState(t('main.buttons.next'));
            setStep(step - 1);
        }
    }

    async function handleConfirm() {
        const body = {
            workOrder: undefined,
            slot: {
                technicianId: undefined,
                startDate: undefined,
                endDate: undefined,
            },
            coordinates: {
                phone: undefined,
                email: undefined,
            },
        };
        body.workOrder = workOrder;
        body.slot.technicianId = selectedSlot.technicianId;
        body.slot.startDate = selectedSlot.start;
        body.slot.endDate = selectedSlot.end;
        body.coordinates.phone = newPhoneNumber;
        body.coordinates.email = newEmailAddress;

        return fetch(
            `/service/${uuid}/schedule`,
            {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(body),
            },
        ).then((response) => response);
    }

    function generateCalendar(calendar) {
        let startDate = (workOrder.status === 1 ? new Date(selectedSlot.start).toISOString() : new Date(workOrder.scheduledStartDate).toISOString());
        startDate = `${startDate.split('.')[0]}Z`;
        startDate = startDate.replace(new RegExp('-', 'g'), '');
        startDate = startDate.replace(new RegExp(':', 'g'), '');
        let endDate = (workOrder.status === 1 ? new Date(selectedSlot.end).toISOString() : (new Date(workOrder.scheduledEndDate).toISOString()));
        endDate = `${endDate.split('.')[0]}Z`;
        endDate = endDate.replace(new RegExp('-', 'g'), '');
        endDate = endDate.replace(new RegExp(':', 'g'), '');
        const Ical = 'BEGIN:VCALENDAR\n'
            + 'VERSION:2.0\n'
            // "PRODID: -//hacksw/handcal//NONSGML v1.0//EN\n"+
            + 'BEGIN:VEVENT\n'
            + `DTSTAMP:${String(new Date().toISOString()).split('.')[0]}${'Z'.replace(new RegExp('-', 'g'), '').replace(new RegExp(':', 'g'), '')}\n`
            + `DTSTART:${startDate}\n`
            + `DTEND:${endDate}\n`
            + `SUMMARY:${workOrder.type.name}\n`
            + `LOCATION:${workOrder.address} ${workOrder.zipcode}, ${workOrder.city}\n`
            + `DESCRIPTION:${workOrder.description}\n`
            + 'END:VEVENT\n'
            + 'END:VCALENDAR';
        const link = document.createElement('a');

        if (navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPad')) {
            const blob = new Blob([Ical], {type: 'text/calendar'});
            link.href = window.URL.createObjectURL(blob);
            link.download = 'event.ics';
            link.click();
        } else if (navigator.userAgent.includes('Android')) {
            link.href = `https://www.google.com/calendar/render?action=TEMPLATE&text=${workOrder.type.name.replace(new RegExp(' ', 'g'), '%20')}&dates=${startDate}/${endDate}&details=${workOrder.description.replace(new RegExp(' ', 'g'), '%20')}&location=${workOrder.address.replace(new RegExp(' ', 'g'), '%20')}+${workOrder.zipcode},+${workOrder.city}&sf=true&output=xml`;
            link.click();
        } else if (navigator.userAgent.includes('Windows') || navigator.userAgent.includes('Macintosh')) {
            switch (calendar) {
                case 'google': {
                    window.open(`https://www.google.com/calendar/render?action=TEMPLATE&text=${workOrder.type.name.replace(new RegExp(' ', 'g'), '%20')}&dates=${startDate}/${endDate}&details=${workOrder.description.replace(new RegExp(' ', 'g'), '%20')}&location=${workOrder.address.replace(new RegExp(' ', 'g'), '%20')}+${workOrder.zipcode},+${workOrder.city}&sf=true&output=xml`);
                    break;
                }
                case 'yahoo': {
                    window.open(`https://calendar.yahoo.com/?desc=${workOrder.description.replace(new RegExp(' ', 'g'), '%20')}&et=${endDate}&in_loc=${workOrder.address.replace(new RegExp(' ', 'g'), '%20')}+${workOrder.zipcode},+${workOrder.city}&st=${startDate}&title=${workOrder.type.name.replace(new RegExp(' ', 'g'), '%20')}&v=60`);
                    break;
                }

                case 'outlook': {
                    let startDateOutlook = (workOrder.status === 1 ? new Date(selectedSlot.start).toISOString() : new Date(workOrder.scheduledStartDate).toISOString());
                    startDateOutlook = `${startDateOutlook.split('.')[0]}%2B${startDateOutlook.split('.')[1]}`;
                    startDateOutlook = startDateOutlook.replace(new RegExp(':', 'g'), '%3A');
                    let endDateOutlook = (workOrder.status === 1 ? new Date(selectedSlot.end).toISOString() : (new Date(workOrder.scheduledEndDate).toISOString()));
                    endDateOutlook = `${endDateOutlook.split('.')[0]}%2B${endDateOutlook.split('.')[1]}`;
                    endDateOutlook = endDateOutlook.replace(new RegExp(':', 'g'), '%3A');
                    window.open(`https://outlook.live.com/calendar/0/action/compose?body=${workOrder.description.replace(new RegExp(' ', 'g'), '%20')}&enddt=${endDateOutlook}&location=${workOrder.address.replace(new RegExp(' ', 'g'), '%20')}+${workOrder.zipcode},+${workOrder.city}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${startDateOutlook}&subject=${workOrder.type.name.replace(new RegExp(' ', 'g'), '%20')}`);
                    break;
                }

                case 'ical': {
                    const blob = new Blob([Ical], {type: 'text/calendar'});
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'event.ics';
                    link.click();
                }
            }
        }
    }

    if (workOrder !== undefined && workOrder.error === undefined) {
        if (workOrderState === WorkOrderState.NO_SCHEDULING) {
            return unauthorizedSchedulePageConfiguration && (
                <>
                    <AdminHeader />
                    <div className="generalWrapper">

                        <div className="params">
                            <h1>{t(unauthorizedSchedulePageConfiguration.title)}</h1>
                        </div>

                        <div className="selectWrapper">
                            <div className="centerPanel">
                                <div className="dataDisplay">
                                    <p>
                                        {t(unauthorizedSchedulePageConfiguration.description)}
                                        <br />
                                        {unauthorizedSchedulePageConfiguration.phoneNumber}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <Footer />
                        {affix}
                    </div>
                </>
            );
        }
        if (workOrderState === WorkOrderState.IN_PROGRESS) {
            return (
                <WorkOrderSummaryPage
                    title="main.title.inProgress"
                    workOrder={workOrder}
                    selectedSlot={selectedSlot}
                    additonalInfo={additionalInfo}
                    newPhoneNumber={newPhoneNumber}
                    newEmailAddress={newEmailAddress}
                    rescheduleAppointment={rescheduleAppointment}
                    saveCalendar={() => setOpenDrawer(true)}
                    affix={affix}
                />
            );
        }
        if (step === CONFIRMATION_PAGE && workOrderState === WorkOrderState.ETA && eta) {
            return (
                <>
                    <AdminHeader />
                    <div className="generalWrapper">
                        <div className="params params-tracking"><h1>{t('main.title.tracking')}</h1></div>
                        <TechTracking
                            latDest={eta.latitudeDest}
                            lngDest={eta.longitudeDest}
                            latTech={eta.latitudeTech}
                            lngTech={eta.longitudeTech}
                            apiKey={eta.apiKey}
                            workOrder={workOrder}
                            selectedSlot={selectedSlot}
                            additonalInfo={additionalInfo}
                            newPhoneNumber={newPhoneNumber}
                            newEmailAddress={newEmailAddress}
                            rescheduleAppointment={rescheduleAppointment}
                            saveCalendar={() => setOpenDrawer(true)}
                        />
                    </div>
                    <Footer />
                    {affix}
                </>
            );
        }
        if (step === DETAILS_FORM) {
            return (
                <>
                    <AdminHeader />
                    <div className="generalWrapper">
                        <div className="params">
                            <h1>{t('main.title.booking')}</h1>
                        </div>
                        <div className="selectWrapper">
                            <Progress step={step} />
                            <div className="centerPanel">

                                <div className="dataDisplay">
                                    {customerForm}
                                </div>
                                <div className="navButtons">
                                    <Tooltip title={formValidation ? undefined : t('form.tooltip')}>
                                        <Button
                                            className="nextStep"
                                            onClick={() => handleNextStep()}
                                            disabled={!formValidation}
                                        >
                                            {buttonState}
                                        </Button>
                                    </Tooltip>
                                </div>
                            </div>

                        </div>

                        <Footer/>
                        {affix}
                    </div>
                </>
            );
        }
        if (step === SLOT_SELECTION) {
            return (
                <>
                    <AdminHeader />
                    <div className="generalWrapper">
                        <div className="params">
                            <h1>{t('main.title.booking')}</h1>
                        </div>
                        <div className="selectWrapper">
                            <Progress step={step} hideFirstStep={isRescheduling}/>
                            <div className="centerPanel">

                                <div className="dataDisplay">
                                    <SlotSelection
                                        selectSlot={selectSlot}
                                        resetSlot={resetSlot}
                                        duration={workOrder.duration}
                                        entId={workOrder['ent.uuid']}
                                        zipcode={workOrder.zipcode}
                                        typeId={workOrder.type.id}
                                        organizationalUnitId={workOrder.organizationalUnitId}
                                        uuid={uuid}
                                        authorizedDelay={workOrder.authorizedDelay}
                                        startDateDelay={getSlotAttributesConfiguration?.startDateDelay}
                                        endDateDelay={getSlotAttributesConfiguration?.endDateDelay}
                                    />

                                </div>

                                <div className="navButtons">
                                    {isRescheduling || (
                                        <Button
                                            className="previousStep"
                                            type="link"
                                            icon={<FontAwesomeIcon icon="arrow-left" />}
                                            onClick={() => handlePreviousStep()}
                                        >
                                            {t('main.buttons.previous')}
                                        </Button>
                                    )}
                                    <Tooltip title={selectedSlot === undefined ? t('slots.tooltip') : undefined}>
                                        <Button
                                            className="nextStep"
                                            disabled={selectedSlot === undefined}
                                            onClick={() => handleNextStep()}
                                        >
                                            {buttonState}
                                        </Button>
                                    </Tooltip>

                                </div>
                            </div>
                        </div>

                        <Footer />
                        {affix}
                    </div>
                </>
            );
        }
        if (step === SUMMARY_FORM) {
            return (
                <>
                    <AdminHeader />
                    <div className="generalWrapper">

                        <div className="params">
                            <h1>{t('main.title.booking')}</h1>

                        </div>

                        <div className="selectWrapper">
                            <Progress step={step} hideFirstStep={isRescheduling} />
                            <div className="centerPanel">

                                <div className="dataDisplay">

                                    <Summary
                                        workOrder={workOrder}
                                        selectedSlot={selectedSlot}
                                        additionalInfo={additionalInfo}
                                        newPhoneNumber={newPhoneNumber}
                                        newEmailAddress={newEmailAddress}
                                    />

                                </div>
                                <div className="navButtons">
                                    <Button
                                        className="previousStep"
                                        type="link"
                                        icon={<FontAwesomeIcon icon="arrow-left" />}
                                        onClick={() => handlePreviousStep()}
                                    >
                                        {t('main.buttons.previous')}
                                    </Button>
                                    <Button className="nextStep" onClick={() => handleNextStep()}>
                                        {buttonState}
                                    </Button>
                                </div>
                            </div>

                        </div>

                        <Footer />
                        {affix}
                    </div>
                </>
            );
        }
        if (step === CONFIRMATION_PAGE) {
            const yesterday = new Date(workOrder.scheduledStartDate);
            yesterday.setDate(yesterday.getDate() - 1);
            const newETA = new Date();
            let ETAMessage = t('lateAppointment');
            if (travelTime && travelTime > 0) {
                newETA.setMilliseconds(newETA.getMilliseconds() + travelTime);
                ETAMessage += t('lateAppointmentETA', {
                    ETA: newETA.toLocaleString(
                        t('date.locale'),
                        {
                            hour: '2-digit',
                            minute: '2-digit',
                        },
                    ),
                });
            }
            return (
                <>
                    <AdminHeader />
                    <div className="generalWrapper booking-confirmation-wrapper">
                        <div className="params">
                            <h1>{t('main.title.confirmed')}</h1>
                        </div>

                        {alert?.alertValue === AchievementAlertValue.LATE
                            ? (
                                <Alert
                                    className="isLateAlert"
                                    message={ETAMessage}
                                    description={t('lateAppointmentDescription')}
                                    type="error"
                                    showIcon
                                />
                            )
                            : alert?.alertValue === AchievementAlertValue.FAILED
                                ? (
                                    <Alert
                                        className="isLateAlert"
                                        message={ETAMessage}
                                        description={t('lateAppointmentDescription')}
                                        type="error"
                                        showIcon
                                    />
                                )
                                : <></>}
                        {/* <div className="rescheduleButtons">
                            {((alert && alert.alertValue !== AchievementAlertValue.RISK) ? (
                                <><Button className="editButton" onClick={() => rescheduleAppointment()}>{t("main.buttons.edit")} &nbsp;
                                    <FontAwesomeIcon
                                        icon="pencil"/></Button></>

                            ) : <></>)}
                        </div> */}

                        <div className="selectWrapper">
                            <div className="centerPanel">
                                <div className="dataDisplay">
                                    <BookingConfirmation
                                        workOrder={workOrder}
                                        selectedSlot={selectedSlot}
                                        additonalInfo={additionalInfo}
                                        newPhoneNumber={newPhoneNumber}
                                        newEmailAddress={newEmailAddress}
                                        rescheduleAppointment={rescheduleAppointment}
                                        saveCalendar={() => setOpenDrawer(true)}
                                        withCalendarButton
                                        withRescheduleButton
                                        withAppointmentRange
                                    />
                                </div>
                            </div>
                        </div>

                        <Footer/>
                        {affix}
                        <Drawer title={t('main.buttons.save')} placement="right" onClose={onClose} open={openDrawer}>
                            <div className="agendaPanelButtonContainer">
                                <Button
                                    className="agendaPanelButton"
                                    onClick={() => generateCalendar('google')}
                                >
                                    Google
                                    calendar
                                </Button>
                            </div>
                            <div className="agendaPanelButtonContainer">
                                <Button
                                    className="agendaPanelButton"
                                    onClick={() => generateCalendar('yahoo')}
                                >
                                    Yahoo
                                    calendar
                                </Button>
                            </div>
                            <div className="agendaPanelButtonContainer">
                                <Button
                                    className="agendaPanelButton"
                                    onClick={() => generateCalendar('outlook')}
                                >
                                    Outlook
                                    calendar
                                </Button>
                            </div>
                            <div className="agendaPanelButtonContainer">
                                <Button
                                    className="agendaPanelButton"
                                    onClick={() => generateCalendar('ical')}
                                >
                                    iCal
                                </Button>
                            </div>
                        </Drawer>
                    </div>
                </>
            );
        }
        if (step === FINISH_PAGE) {
            return (
                <WorkOrderSummaryPage
                    title={'main.title.completed'}
                    workOrder={workOrder}
                    selectedSlot={selectedSlot}
                    additonalInfo={additionalInfo}
                    newPhoneNumber={newPhoneNumber}
                    newEmailAddress={newEmailAddress}
                    rescheduleAppointment={rescheduleAppointment}
                    saveCalendar={() => setOpenDrawer(true)}
                />
            );
        }
        return (<div>Page not found.</div>);
    }
    if (workOrder !== undefined) {
        if (workOrder.error !== undefined) {
            return (
                <div>
                    <h1>{workOrder.error}</h1>
                </div>
            );
        }
        return (<h1>Error</h1>);
    }
    return (
        <div className="spin">
            <Spin size="large" />
        </div>
    );
}
